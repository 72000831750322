import 'alpinejs';
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

// App main
const main = async () => {
    // const Iodine = await import(/* webpackChunkName: "Iodine" */'@kingshott/iodine/dist/iodine.min.esm');
    // return Iodine;

    const lazysizes = await import(/* webpackChunkName: "lazysizes" */'lazysizes');
    return lazysizes;

    return null;
};

// Execute async function
main().then( (root) => {
    loadVideos();
    loadFlatpicker();
    loadForms();
});

// Swiper
var cardSwiper = new Swiper(".card-swiper", {
    slidesPerView: 1.25,
    spaceBetween: 16,
    breakpoints: {
        640: {
        slidesPerView: 2.15,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 2.25,
            spaceBetween: 16,
        },
        1024: {
            slidesPerView: 2.25,
            spaceBetween: 32,
        },
        1280: {
            slidesPerView: 4,
            spaceBetween: 32,
        }
    },
});

var cardSwiper = new Swiper(".blog-swiper", {
    slidesPerView: 1.3,
    spaceBetween: 16,
    breakpoints: {
        640: {
            slidesPerView: 1.25,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 3.2,
            spaceBetween: 16,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 32,
        },
    },
});

var cardSwiper = new Swiper(".highlights-swiper", {
    slidesPerView: 1.2,
    spaceBetween: 16,
    breakpoints: {
        640: {
            slidesPerView: 2.3,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 2.5,
            spaceBetween: 32,
        },
        1024: {
            slidesPerView: 3.3,
            spaceBetween: 32,
        },
        1280: {
            slidesPerView: 4,
            spaceBetween: 32,
        },
    },
});

var cardSwiper = new Swiper(".slideshow-swiper", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    effect: 'slide',
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },

});

const videoJSLoader = async () => {
    const { default: Plyr } = await import(/* webpackChunkName: "Plyr" */'plyr');

    return Plyr;
}

const datePickerLoader = async () => {
    const { default: flatpickr } = await import(/* webpackChunkName: "flatpickr" */'flatpickr');
    return flatpickr;
}

const formLoader = async () => {
    const { default: HyperForm } = await import(/* webpackChunkName: "hyperForm" */'hyperform');

    return HyperForm;
}

const loadVideos = async () => {
    const videosOnPage = document.querySelectorAll('.plyr__video-embed');

    if(videosOnPage.length > 0) {
        videoJSLoader().then( Plyr => {
            let videosOnPage = document.querySelectorAll('.plyr__video-embed');

            videosOnPage.forEach(function(elm) {
                const player = new Plyr(elm);
            })
        });
    }
}

const loadFlatpicker = async () => {
    const flatPickerOnPage = document.querySelectorAll('.flatpickr');

    if(flatPickerOnPage.length > 0) {
        datePickerLoader().then( flatpickr => {
            let flatPickerOnPage = document.querySelectorAll('.flatpickr');

            flatPickerOnPage.forEach(function(elm) {
                flatpickr(elm, {
                    minDate: "today",
                    "disable": [
                        function(date) {
                            return (date.getDay() === 1);
                        }
                    ],
                });
            })
        });
    }
}

const loadForms = async () => {
    const formsOnPage = document.querySelectorAll('.this-is-hyper');

    if(formsOnPage.length > 0) {
        formLoader().then( HyperForm => {

            // if(document.querySelector('[name=confirmPassword]') != null) {
            //     HyperForm.addValidator(
            //         document.querySelector('[name=confirmPassword]'),
            //         function (element) {
            //             var valid = element.value === document.querySelector('[name=newPassword]').value;
            //             return valid;
            //         }
            //     );
            // }

            formsOnPage.forEach(function(elm) {
                var $form = HyperForm(elm, {
                    classes: {
                        valid: 'valid-input',
                        invalid: 'error-input',
                        warning: 'hyper-error'
                    }
                });
            })
        });
    }
}


// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}

